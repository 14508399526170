import React from "react";
import { AiOutlineLinkedin } from "react-icons/ai";
import {Link} from "react-router-dom"
import adam from "../../Assets/aboutusimg/adam.png";
import bini from "../../Assets/aboutusimg/bini.png";
import ell from "../../Assets/aboutusimg/ell.png";
import nathan from "../../Assets/aboutusimg/nathan.png";

const AboutUs = () => {
  const image = [
    {
      img: bini,
      Nametext: "Dr. Biniyam Alemu",
      position: "CEO and Co-founder",
      link: "https://www.linkedin.com/in/binyam-alemu-md-390575209",
    },
    {
      img: ell,
      Nametext: "Dr. Ellias Tadesse",
      position: "COO and Co-founder",
      link: "https://www.linkedin.com/in/elias-tadesse-md-48a700209",
    },
    {
      img: adam,
      Nametext: "Dr. Adam Getachew",
      position: "CTO and Co-founder",
      link: "https://www.linkedin.com/in/adam-getachew-4b5446151",
    },
    {
      img: nathan,
      Nametext: "Dr. Nathan Muluberhan",
      position: "CPD director and Co-founder",
      link: "https://www.linkedin.com/in/dr-nathan-muluberhan-72b2a528",
    },
  ];
  return (
    <div id="About" className="flex md:flex-row flex-col justify-between items-center bg-blue md:p-0 p-4">
      <div className="flex flex-col justify-center md:items-start items-center px-12">
        <h1 className="md:text-3xl">About Us</h1>
        <div className="divider" />
        <p className="text-center md:py-5 py-3 md:text-lg">
          We are Blue Health’s founders. Meet our young and talented team.{" "}
        </p>
      </div>
      <div className="grid grid-cols-2">
        {image.map((items) => (
          <div className="grid-item ">
            <img src={items.img} alt="" className="object-cover md:w-56 w-44" />
            <div className="overlay-text">
              <div className="flex flex-col">
                <h1 className="font-bold md:text-xl text-sm tracking-normal">
                  {items.Nametext}
                </h1>
                <p className="text-sm">{items.position}</p>
              </div>
              <div className="">
                <Link to={items.link}><AiOutlineLinkedin className="text-3xl hover:text-[#0072b1]" /></Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
