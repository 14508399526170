import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
export function LoginForm() {
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault()
    // Authenticate user
    if (rememberMe) {
      localStorage.setItem("authToken", "yourAuthToken");
      console.log(rememberMe)
    }
    // Redirect or do other actions
  };

  return (
    <form action="" className="flex flex-col space-y-2" onSubmit={handleLogin}>
      <h2 className="font-bold">Email</h2>
      <input
        type="Email"
        placeholder="Enter your email"
        className="p-1 w-80 outline-none"
        required
      />
      <h2 className="font-bold">Password</h2>
      <input
        type="password"
        placeholder="Enter your password"
        className="p-1 outline-none"
        required
      />
      <div className="flex flex-row justify-between">
        <div className="">
          <input
            type="checkbox"
            name=""
            id=""
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />{" "}
          Remeber me
        </div>
        <Link to='/'>Forget Password?</Link>
      </div>
      <div className="flex flex-col justify-center items-center space-y-2">
        {" "}
        <button onClick={()=>{navigate('/dashboard')}} className="bg-primary w-80 p-1 text-white font-bold hover:bg-secondary">
          Sign in
        </button>
        <p>
          Don't have an account?{" "}
          <Link to="/signup" className="text-primary">
            Sign Up
          </Link>
        </p>
      </div>
    </form>
  );
}
  