import React from "react";
import SimpleSlider from "../../Component/SimpleSlider";
import award1 from "../../Assets/Awards/award1.jpg";
import award2 from "../../Assets/Awards/award2.jpg";
import award3 from "../../Assets/Awards/award3.jpg";
import award4 from "../../Assets/Awards/award4.jpg";
import award5 from "../../Assets/Awards/award5.jpg";
import award6 from "../../Assets/Awards/award6.jpg";
import award7 from "../../Assets/Awards/award7.jpg";

const Awards = () => {
  const awards = [
    { img: award1, text: "Our First Aid Training Partners" },
    { img: award2, text: "Working with the CHFE" },
    { img: award3, text: "CPD Provider License" },
    { img: award4, text: "We are an International Training SiteE" },
    { img: award5, text: "Recognition from the MOH" },
    { img: award6, text: "Recognition form the health bureau" },
    { img: award7, text: "Social Duty Alliance" },
  ];
  return (
    <div className="p-12">
      <div className="flex flex-col items-center p-3">
        <h1 className="md:text-3xl">Awards And Recognition</h1>
        <div className="divider" />
      </div>
      <SimpleSlider images={awards} />
    </div>
  );
};

export default Awards;
