import React from 'react'
import { Link } from 'react-router-dom'
import bini from '../Assets/aboutusimg/bini.png'
import BookingCards from './BookingCards';

const SideNav = () => {
    const list = [
      {
        num: 20,
        name: "Seminar Taken",
      },
      {
        num: 15,
        name: "Test Taken",
      },
      {
        num: 15,
        name: "CEU collection",
      },
      {
        num: 15,
        name: "CEU collection",
      },
  ];
  const scroll = list.length > 3
     const link = [{}, {}, {}, {}, {}];
  return (
    <div className="flex flex-row flex-1">
      <div className="bg-primary h-screen p-4 top-0 w-96 text-white flex flex-col items-center overflow-y-scroll overflow-x-hidden">
        <img src={bini} alt="" className="w-32 rounded-full" />
        <h1>Dr.Biniyiam</h1>
        <p>Thistheemail@gmail.com</p>
        <p>Adult Caedioligist</p>
        <div className="flex flex-row justify-center items-center">
          {list.map((items) => (
            <div className="flex flex-col items-center p-3">
              <h1 className="text-secondary">{items.num}</h1>
              <p className="w-full text-center">{items.name}</p>
            </div>
          ))}
        </div>
        <div className="">
          <h1>Certificates</h1>
          <div className="divider" />
          <div className="flex flex-col space-y-3 text-md text-gray underline ">
            {link.map((items) => (
              <Link className="hover:text-secondary">
                Approach to supraventricular tachycardia
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row ${
          scroll ? "max-w-full overflow-x-scroll whitespace-nowrap" : ""
        }`}>
        {list.map((items) => (
          <BookingCards />
        ))}
      </div>
    </div>
  );
}

export default SideNav
