import React from "react";
import { MdOutlineReadMore } from "react-icons/md";
import { Link } from "react-router-dom";

const CPD = () => {
  return (
    <div className="bg-bgimg bg-fixed bg-cover bg-no-repeat md:bg-center">
      <div className="p-12 bg-white w-full h-full bg-opacity-70 flex flex-col justify-center items-center">
        <h1 className="md:text-3xl">CPD Program</h1>
        <div className="divider" />
        <p className="text-center md:w-3/4 w-screen md:p-5 p-4 md:text-lg">
          Blue Health CPD is a web based learning management system build up on
          moodle which is created with an intention of updating our precious
          health professionals with the current management updates of different
          health related topic coneniently where they can take the courses in
          the site at their own pace and anywhere they are easily by using their
          personal computer or their smart phones. <br/> We know how busy health
          professionals are in their normal day today tasks and it would not be
          fair to ask them to attend all of our in-person CPD trainings so we
          prepared this platform to help them update their knowledge while
          getting CPD points alternatively FOR BETTER HEALTH CARE
        </p>
        <Link
          to="https://cpd.bluehealthethiopia.com/"
          className="flex space-x-3 items-center font-bold md:px-4 px-3 py-1 border-2 border-secondary hover:border-primary duration-100 ease-in-out">
          <p className="md:text-xl text-sm">Visit Site</p>
          <MdOutlineReadMore className="md:text-3xl text-md text-black " />
        </Link>
      </div>
    </div>
  );
};

export default CPD;
