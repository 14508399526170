import React from 'react'
import logoblue from "../../Assets/logoblue.png";

const BlueDesc = () => {
  return (
    <div className="bg-blue flex flex-col justify-end items-center relative md:p-12 px-4 py-12 md:pt-44">
      <img src={logoblue} alt="" className="w-64 absolute left-0" />
      <div className="p-12 flex flex-col justify-center items-center z-50">
        <h1 className="md:text-3xl">Blue Health Ethiopia</h1>
        <div className="divider" />
        <p className="text-center md:w-3/4 w-screen md:p-5 p-4 md:text-lg">
          Blue Health Ethiopia is a medical consultancy company founded by
          medical doctors of Haramaya University graduatesand an experienced
          computer engineer. The company is founded as a social enterprise with
          the aim of promoting preventive medicine by which we lead the road to
          future healthcare.
        </p>
          </div>
          {/* <Progress/> */}
    </div>
  );
}

export default BlueDesc
