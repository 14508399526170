import React from "react";

const BookingCards = () => {
  return (
    <div className="fixed">
      <div className="border-2 border-primary p-2">
        
      </div>
  </div>
  );
};

export default BookingCards;
