import React from "react";
import { MdOutlineReadMore } from "react-icons/md";
import { Link } from "react-router-dom";

const NewsFeed = () => {
  const news = [
    {
      image: "news1",
      headline: "Blue Health Ethiopia in GITEX Global at Dubai Harbor",
      text:
        "We're thrilled to be a part of GITEX Global 2023, showcasing our innovations in the emergency sector." +
        "At Blue Health Ethiopia, we're on a mission to transform the way emergency services operate in Ethiopia, and our goal is simple: to save lives. Through innovative digital solutions, we're working tirelessly to prevent accidents related deaths and reduce severe injuries.",
      link: "https://www.facebook.com/bluehealthethiopia",
    },
    {
      gridplace: "",
      image: "news2",
      headline: "FIRST AID IS FOR EVERYONE ,EVERYWHERE",
      text:
        "During our journey to provide First Aid training at Haile Manas Academy in Debre Brehan, our Blue Health Ethiopia team encountered a horrific accident. A speeding car in front of us crashed, and while several vehicles stopped, desperately wanting to help but not knowing how, it became clear that there is a pressing need for more individuals to undergo basic first aid training." +
        "The public transport vehicle involved in the accident carried 16 people, five of whom were injured: one with a shoulder dislocation, another with hand and leg bleeding, an older woman with a spinal injury, and one with a rib fracture. Fortunately, our team was able to administer First Aid to all of them",
      link: "https://www.facebook.com/bluehealthethiopia",
    },
    {
      gridplace: "",
      image: "news4",
      headline:
        "Blue Health Ethiopia proudly announces its strategic partnership",
      text: "Blue Health Ethiopia proudly announces its strategic partnership with the Ethio Health Exhibition and Congress, marking a significant collaboration to advance healthcare services and innovation throughout Ethiopia.",
      link: "https://www.linkedin.com/posts/ethio-health_pranaevents-ethiohealth-healthcareexpo-activity-7145428131489673217-EN4d?utm_source=share&utm_medium=member_desktop",
    },
  ];
  return (
    <div
      id="News"
      className="flex md:flex-row-reverse flex-col justify-between items-center ">
      <div className="flex flex-col md:items-end items-center md:p-12 p-6">
        <h1 className="md:text-3xl">News Feed</h1>
        <div className="divider" />
        <p className="md:text-end text-center md:py-5 py-3 md:text-lg">
          This is Blue Health news feed get informations and news about blue
          health here
        </p>
        <Link
          to="https://www.facebook.com/bluehealthethiopia"
          className="flex space-x-3 items-center font-bold md:px-4 px-3 py-1 border-2 border-secondary hover:border-primary duration-100 ease-in-out">
          <p className="md:text-xl text-sm">Read More</p>
          <MdOutlineReadMore className="md:text-3xl text-md text-black " />
        </Link>
      </div>
      <div className="md:grid flex flex-col justify-center items-center grid-cols-2 grid-rows-2 md:gap-x-8 gap-3 px-12 py-4">
        {news.map((items) => (
          <Link to={items.link}>
            {" "}
            <div
              className={`${items.gridplace} bg-${items.image} bg-center bg-cover md:w-72 h-64`}>
              <div className="w-full h-full bg-black bg-opacity-40 flex flex-col justify-end p-2 text-white">
                {" "}
                <h1 className="">{items.headline}</h1>
                <p>{items.text.slice(0, 95)}...</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NewsFeed;
