import React from "react";
import { Link } from "react-scroll";
import Logo from "../../Assets/Logo Transparent 1.png";
import LogoColor from "../../Assets/Logo Transparent 3.png";

const DesktopNav = ({ sticky }) => {
  const Nav = [
    { link: "Home", scroll: "Home" },
    { link: "Services", scroll: "Services" },
    { link: "Medical Toursim", scroll: "Toursim" },
    { link: "News Feed", scroll: "News" },
    { link: "About Us", scroll: "About" },
    { link: "Contact Us", scroll: "Contact" },
  ];
  return (
    <div
      whileInView="visible"
      className={
        !sticky
          ? `md:flex justify-between items-center hidden backdrop-blur-sm bg-opacity-70 py-3 absolute w-full z-50`
          : "fixed hidden w-full z-[11111] md:flex justify-between items-center py-3 bg-white"
      }>
      <Link to="Home" spy={true} smooth={true} offset={-100} duration={1000}>
        <img
          src={sticky ? LogoColor : Logo}
          alt=""
          className="w-40 mx-12 cursor-pointer"
        />
      </Link>
      <div className="text-sm space-x-7 bg-white p-4 right-0 blur-none">
        {Nav.map((items) => (
          <Link
            to={items.scroll}
            spy={true}
            smooth={true}
            offset={-100}
            duration={1000}
            className="hover:underline hover:text-secondary p-1 text-black duration-200 ease-in-out cursor-pointer">
            {items.link}
          </Link>
        ))}
        {/* <button
          onClick={() => {
            navigate("/signin");
          }}
          className="bg-primary p-1 px-4 text-white font-bold hover:bg-secondary duration-100 ease-in-out">
          Sign In
        </button> */}
      </div>
    </div>
  );
};

export default DesktopNav;
