import React from "react";
import TemplateSlider from "../../Component/CardTemplateSlider";
import vector1 from "../../Assets/Vector/HealthApp..jpg";
import vector2 from "../../Assets/Vector/Research..jpg";
import vector3 from "../../Assets/Vector/Training..jpg";
// import vector4 from "../../Assets/Vector/medical toursim vector@4x.png";

const Services = () => {
  const data = [
    {
      id: 1,
      header: "Health App",
      text: "By developing mobile and web applications we aim to equip our community with basic health related knowledge",
      img: vector1,
      nav: `/servicedetail/1`,
    },
    {
      id: 2,
      header: "Research",
      text: "We are working to improve the health problems of Ethiopia by knowing it's core problem through researches",
      img: vector2,
      nav: `/servicedetail/2`,
    },
    {
      id: 3,
      header: "Traning",
      text: "Through the training we give, we aim to equip our community with the knowledge of different essential health issues",
      img: vector3,
      nav: `/servicedetail/3`,
    },
    {
      id: 4,
      header: "Medical Toursim",
      text: "Blue Health Ethiopia in partnership with HBG Medical Assistance is dedicated to providing the highest quality healthcare services in India and ensuring that patients receive the best possible medical care and treatment.",
      img: vector3,
      nav: "/toursimdetail",
    },
  ];
  return (
    <div id="Services" className="p-14 bg-graywhite">
      <div className="flex flex-col justify-center items-center">
        <h1 className="md:text-3xl">Our Services</h1>
        <div className="divider" />
        <p className="text-center md:w-3/4  md:p-5 p-4 md:text-lg">
          Here are some of the services we provide
        </p>
      </div>
      <TemplateSlider card={data} />
    </div>
  );
};

export default Services;
