import React, { useEffect } from "react";
import logo from "../../Assets/Logo Transparent 1.png";
import { RiSendPlaneLine, RiBuilding2Line } from "react-icons/ri";
import { RxMagicWand } from "react-icons/rx";
import { BsHouseDoor } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
import { Link } from "react-router-dom";

const ToursimDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const btn = [
    {
      title: "Cancer",
      link: "https://hbgmedicalassistance.com/cancer-treatment-in-india/",
    },
    {
      title: "Cardiac",
      link: "https://hbgmedicalassistance.com/cardiac-treatment-in-india/",
    },
    {
      title: "Orthopedics",
      link: "https://hbgmedicalassistance.com/joint-and-orthopedics-treatments-in-india/",
    },
    {
      title: "Neurosurgery",
      link: "https://hbgmedicalassistance.com/neurology-and-neurosurgery-treatments/",
    },
    {
      title: "Spine Surgery",
      link: "https://hbgmedicalassistance.com/spine-treatment-in-india/",
    },
    {
      title: "Joint Replacement",
      link: "https://hbgmedicalassistance.com/joint-and-orthopedics-treatments-in-india/",
    },
    {
      title: "Transplant",
      link: "https://hbgmedicalassistance.com/organ-transplant-in-india/",
    },
  ];
  const steps = [
    {
      icon: RiSendPlaneLine,
      num: "Step 1",
      text: "Send your details on care@hbgmedicalassistance.com",
    },
    {
      icon: RxMagicWand,
      num: "Step 2",
      text: "Receive quotation(s) within 48 hours",
    },
    {
      icon: RiBuilding2Line,
      num: "Step 3",
      text: "Get received by us in India",
    },
    {
      icon: BsHouseDoor,
      num: "Step 4",
      text: "Get treated and fly back home",
    },
  ];
  const info = [
    {
      header: "Treatment in India",
      text:
        "India is the treatment capital of the world with 1.2 million" +
        "international patients traveling to India annually for affordable" +
        "high-quality treatment. Its world-class medical infrastructure" +
        "skilled doctors, and advanced technology make India a trusted" +
        "destination for medical tourism.",
    },
    {
      header: "Blue Health Ethiopia and HBG",
      text:
        "Blue Health, Ethiopia is a partner of HBG Medical Assistance India, a highly trusted" +
        "healthcare provider for over 10,000 international patients seeking medical treatment in India." +
        "With an extensive network of over 1,000 doctors and 200 hospital units, HBG Medical Assistance" +
        "India offers the widest coverage of healthcare facilities in the country.The company is also" +
        " accredited by NABH, which is a testament to its commitment to providing the highest quality healthcare" +
        "services in India.Blue Health, Ethiopia aims to provide the same level of excellence in healthcare services" +
        "to patients in Ethiopia and beyond.With a team of experienced healthcare professionals and access to some" +
        "of the best healthcare facilities in Ethiopia, Blue Health Ethiopia is dedicated to ensuring that patients" +
        "receive the best possible medical care and treatment.",
    },
  ];
  return (
    <div className="flex flex-col justify-center items-center overflow-hidden space-y-8">
      <div className="w-full bg-hbg bg-cover bg-center md:h-96 h-48 flex flex-row justify-between items-start md:px-9 md:py-4 py-3 px-4">
        <div className="flex flex-row justify-between items-center w-full">
          <Link to={"/"}>
            <img src={logo} alt="" className="w-40" />
          </Link>
          <Link
            to={"/"}
            className="text-white md:text-md text-sm font-bold hover:text-secondary duration-150 ease-in-out">
            BACK TO HOME
          </Link>
        </div>
      </div>
      {info.map((items) => (
        <div className="flex flex-col justify-center items-center">
          <h1 className="md:text-3xl">{items.header}</h1>
          <div className="divider" />
          <p className="text-center md:w-3/4 md:p-5 px-5 py-2 md:text-lg">
            {items.text}
          </p>
        </div>
      ))}
      <div className="flex flex-col justify-center items-center">
        <h1 className="md:text-3xl">How Does it work</h1>
        <div className="divider" />
        <div className="flex md:flex-row flex-col md:justify-between justify-center md:items-center md:py-9 p-9 md:space-x-14 space-y-8">
          {steps.map((items) => (
            <div className="flex flex-col justify-center items-center">
              <items.icon className="md:text-5xl text-4xl text-secondary" />
              <h1>{items.num}</h1>
              <p className="text-center w-48">{items.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="md:text-3xl">Treatments</h1>
        <div className="divider" />
        <div className="grid md:grid-cols-5 grid-cols-2 md:grid-rows-2 grid-rows-4 gap-4 py-9">
          {btn.map((items) => (
            <Link
              to={items.link}
              className="bg-primary text-white flex flex-row justify-center items-center md:px-3 md:py-2 p-1 space-x-5 rounded-lg hover:bg-secondary duration-150 ease-out">
              <p className="md:text-lg text-md">{items.title}</p>
              <AiOutlineLink className="text-2xl" />
            </Link>
          ))}
        </div>
        <div className="flex flex-col justify-center items-center">
          <h1 className="md:text-3xl">Contact Us</h1>
          <div className="divider" />
          <p className="text-center md:w-3/4 w-full md:p-5 p-9 md:text-lg text-sm">
            We welcome you to reach out to us at any time to learn more about
            our services and how we can work together.Starting with a FREE
            Consultation with renowned doctors from India
          </p>
          <p className="text-center md:w-3/4 w-full md:p-5 p-9 md:text-lg text-sm font-semibold">
            <span className="font-bold text-xl text-secondary">
              Contact us :
            </span>{" "}
            +251 909 151521/ kirubel.bluehealthethiopia@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default ToursimDetail;
