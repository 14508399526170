import React from "react";
import SimpleSlider from "../../Component/SimpleSlider";
import logo1 from "../../Assets/PartnerLogo/AHA_Full.png";
import logo2 from "../../Assets/PartnerLogo/Child heart fund.png";
import logo3 from "../../Assets/PartnerLogo/Nokia.png";
import logo4 from "../../Assets/PartnerLogo/Scroll Group 1.png";
import logo5 from "../../Assets/PartnerLogo/TotalEnergies.png";
import logo6 from "../../Assets/PartnerLogo/Ethiohealth-07 2.png";
import logo7 from "../../Assets/PartnerLogo/Prana Logo-01.png";
import logo8 from "../../Assets/PartnerLogo/A2CV.jpeg"

const OurPartner = () => {
  const images = [
    { img: logo1 },
    { img: logo2 },
    { img: logo3 },
    { img: logo4 },
    { img: logo5 },
    { img: logo6 },
    { img: logo7 },
    { img: logo8 },
    // Add more image URLs here
  ];
  return (
    <div className="md:p-12 p-4">
      <div className="flex flex-col justify-center items-center pb-4">
        <h1 className="md:text-3xl">Our Partners</h1>
        <div className="divider" />
      </div>
      <SimpleSlider images={images} />
    </div>
  );
};

export default OurPartner;
