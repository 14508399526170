import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
const TemplateSlider = ({ card }) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="">
      <Slider {...settings}>
        {card.map((items, index) => (
          <div
            key={items.id}
            className="w-full flex flex-col p-2 justify-center items-center cursor-pointer hover:scale-105 duration-100 ease-in-out"
            onClick={() => navigate(`${items.nav}`)}>
            <img src={items.img} alt="" className="object-contain md:w-72" />
            <div className="border-2 border-t-0 border-secondary md:w-72 md:p-2 ">
              <h1 className="md:px-0 px-2">{items.header}</h1>
              <p className="md:text-lg md:p-0 px-2 py-1">
                {items.text.slice(0, 95)}....
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TemplateSlider;
