import { Route, Routes } from "react-router-dom";
import ScrollRoute from "./Router/ScrollRoute";
import LoginPage from "./Pages/Dynamic pages/LoginPage";
import SignUpPage from "./Pages/Dynamic pages/SignUpPage";
import SideNav from "./Component/SideNav";
import ServiceDetail from "./Pages/Static Pages/ServiceDetail";
import ToursimDetail from "./Pages/Static Pages/ToursimDetail";

function App() {
  return (
    <Routes>
      <Route path="/" element={<ScrollRoute />} />
      <Route path="/servicedetail/:id" element={<ServiceDetail />} />
      <Route path="/toursimdetail" element={<ToursimDetail />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/dashboard" element={<SideNav />} />
    </Routes>
  );
}

export default App;
