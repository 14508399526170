import React from "react";
import Slider from "react-slick";

const SimpleSlider = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index} className="w-full md:p-3">
          <div className="aspect-video flex flex-col items-center">
            <img
              src={image.img}
              alt=""
              className="md:w-full w-56 h-full object-contain mix-blend-color-dodge"
            />
            <p className="md:text-lg text-center">{image.text}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default SimpleSlider;
