import React from "react";
import { useNavigate } from 'react-router-dom'
export function SignupForm() {
  const navigate = useNavigate()
  return (
    <form action="" className="flex flex-col space-y-2">
      <h2 className="font-bold">Full Name</h2>
      <input
        type="text"
        placeholder="Enter your full name"
        className="p-1 w-80 outline-none"
        required
      />
      <h2 className="font-bold">Phone Number</h2>
      <input
        type="text"
        placeholder="Enter your phone number"
        className="p-1 w-80 outline-none"
        required
      />
      <h2 className="font-bold">Email</h2>
      <input
        type="email"
        placeholder="Enter your email"
        className="p-1 w-80 outline-none"
        required
      />
      <h2 className="font-bold">Title</h2>
      <select name="" id="" className="p-1 w-80 outline-none">
        <option value="">Doctor</option>
        <option value="">MR.</option>
        <option value="">MIS.</option>
        <option value="">Sir</option>
      </select>
      <h2 className="font-bold">Position</h2>
      <input
        type="text"
        placeholder="Enter your position"
        className="p-1 w-80 outline-none"
        required
      />
      <h2 className="font-bold">Status</h2>
      <select name="" id="" className="p-1 w-80 outline-none">
        <option value="">Doctor</option>
        <option value="">MR.</option>
        <option value="">MIS.</option>
        <option value="">Sir</option>
      </select>
      <button
        onClick={() => {
          navigate("/dashboard");
        }}
        className="bg-primary w-80 p-1 text-white font-bold hover:bg-secondary">
        Sign Up
      </button>
    </form>
  );
}
  