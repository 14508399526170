import { SignupForm } from "../../Controller/signupForm";
import React from "react";
import logo from '../../Assets/Logo Transparent 1.png'
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const SignUpPage = () => {
  return (
    <div className="flex">
      <div className="flex flex-1 flex-col justify-center items-start p-12 h-screen space-y-4">
        <Link to="/signin">
          <IoMdArrowRoundBack className="text-3xl top-3 left-3 absolute hover:text-secondary" />
        </Link>

        <div className="">
          <h1>Let’s Create your account</h1>
          <p>
            Create an account to View Virtual Seminary and Book the latest
            Seminaries{" "}
          </p>
        </div>
        <SignupForm />
      </div>
      <div className="bg-bgimg2 bg-cover bg-center w-full hidden md:flex ">
        <div className="flex flex-col justify-center items-center text-white bg-opacity-20 p-12 bg-gray w-full h-full space-y-5">
          <img src={logo} alt="" className="w-64" />
          <h1>Why Have Account For Virtual Seminary?</h1>
          <p className="text-center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
            dicta fuga illo consequatur, reprehenderit, quos suscipit nobis
            aperiam impedit itaque error esse expedita alias hic, est non odio
            nostrum. Ullam!
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
