import React from "react";
import { PiTelevisionBold, PiTargetBold } from "react-icons/pi";
import { SlDiamond } from "react-icons/sl";
import Slider from "react-slick";
import DescCard from "../../Component/DescCard";
import { Link } from "react-router-dom";

const Home = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const items = [
    {
      icon: PiTargetBold,
      text: "Inspire hope, promote health, safety and well-being by providing the bestcare to the general population through integrated education, focused training, coaching,healthcare digitizing, clinical consultation and research.",
    },
    {
      icon: PiTelevisionBold,
      text: "Our vision is to be an influential healthcare leader in creating a skilledcommunity through easily accessible knowledge in preventive medicine.",
    },
    {
      icon: SlDiamond,
      text: "Respect for humanity, compassion, commitment to integrity, ethical practice,professionalism, commitment to excellence.",
    },
  ];
  const slide = [
    {
      img: "firstaid",
      header: "Blue Health’s First Aid  and AHA BLS Training",
      text: "Through our First Aid trainings we equip our community as well as different industrial sector employees with a tailor made skill and knowledge of saving a life in emergency situations.",
      btn: "Register Now",
      visible: "hidden",
    },
    {
      img: "virtual",
      header: "Blue Health Virtual Seminar",
      text: "A platform that allows health professionals to discuss current management updates of different health topics FOR BETTER PATIENT CARE",
      btn: "Sign In",
      link: "https://cpd.bluehealthethiopia.com/",
    },
    {
      img: "cpd",
      header: "Blue Health Ethiopia's CPD training",
      text: "Blue Health Ethiopia organizes and prepares CPD trainings ondifferent health topics for different organization",
      btn: "Register Now",
      link: "https://cpd.bluehealthethiopia.com/",
    },
    {
      img: "derash",
      header: "Derash(ደራሽ)",
      text: "An android app that guides users what to do in health emergency situations. And much more...",
      btn: "Download App",
      link: "https://play.google.com/store/apps/details?id=com.bluehealthethiopia.derash&hl=en_US&gl=US",
    },
  ];

  return (
    <div id="Home" className="top-0 overflow-hidden bg-blue">
      <Slider {...settings}>
        {slide.map((items) => (
          <div
            className={`${
              "bg-" + items.img
            } bg-cover bg-center h-fit text-white`}>
            <div className="bg-black md:px-12 px-5 py-32 bg-opacity-50 w-full md:h-screen h-fit flex flex-row justify-start items-center ">
              <div className="">
                <h1 className="md:text-5xl text-2xl md:w-3/5  py-1 tracking-widest">
                  {items.header}
                </h1>
                <div className="divider" />
                <p className="md:w-3/6 py-3 md:text-xl mb-4">{items.text}</p>
                <Link
                  to={items.link}
                  className={`border-2 border-secondary md:py-2 py-1 md:px-12 px-6 text-sm md:text-lg font-bold tracking-widest hover:border-primary duration-70 ease-in-out ${items.visible}`}>
                  {items.btn}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="px-12 flex md:flex-row flex-col justify-between md:absolute bg-blue items-center p-2 w-full gap-32 md:gap-0">
        <DescCard header={"Mission"} items={items[0]} />
        <DescCard header={"Vision"} items={items[1]} />
        <DescCard header={"Value"} items={items[2]} />
      </div>
    </div>
  );
};

export default Home;
