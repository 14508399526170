import React, { useEffect, useState } from "react";
// import Blog from "../Pages/Static Pages/Blog";
import DesktopNav from "../Component/Navigation/DesktopNav";
import Loader from "../Component/Loader";
// import UseSticky from "../Component/UseSticky";
import MedicalToursim from "../Pages/Static Pages/MedicalToursim";
import AboutUs from "../Pages/Static Pages/AboutUs";
import ContactUs from "../Pages/Static Pages/ContactUs";
import OurPartner from "../Pages/Static Pages/OurPartner";
import Home from "../Pages/Static Pages/Home";
import Footer from "../Pages/Static Pages/Footer";
import NewsFeed from "../Pages/Static Pages/NewsFeed";
import Services from "../Pages/Static Pages/Services";
import CPD from "../Pages/Static Pages/CPD";
import Awards from "../Pages/Static Pages/Awards";
import BlueDesc from "../Pages/Static Pages/BlueDesc";
import useSticky from "../Component/useSticky";
import PhoneNav from "../Component/Navigation/PhoneNav";

function ScrollRoute() {
  const [isLoading, setLoading] = useState(true); // The holding state for the loading component
  const isSticky = useSticky(500);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* loading component rendered */}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-hidden">
            <DesktopNav sticky={isSticky} />
            <PhoneNav sticky={isSticky}/>
          <Home />
          <BlueDesc />
          <Services />
          <MedicalToursim />
          <CPD />
          <Awards />
          {/* <Blog/> */}
          <NewsFeed />
          <AboutUs />
          <OurPartner />
          <ContactUs />
          <Footer />
        </div>
      )}
    </>
  );
}

export default ScrollRoute;
