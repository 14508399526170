import React, { useState } from "react";
import { Link } from "react-scroll";
import Logo from "../../Assets/Logo Transparent 1.png";
import LogoColor from "../../Assets/Logo Transparent 3.png";
import { BiMenuAltRight } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const PhoneNav = ({ sticky }) => {
  const [isOpen, setIsOpen] = useState(true);  
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const Nav = [
    { link: "Home", scroll: "Home" },
    { link: "Services", scroll: "Services" },
    { link: "Medical Toursim", scroll: "Toursim" },
    { link: "News Feed", scroll: "News" },
    { link: "About Us", scroll: "About" },
    { link: "Contact Us", scroll: "Contact" },
  ];
  return (
    <div
      className={
        sticky
          ? "fixed overflow-hidden md:hidden bg-white w-full py-3 flex justify-between items-center z-[1111] px-4"
          : "fixed overflow-hidden md:hidden bg-transparent w-full py-3 flex justify-between items-center z-[1111] text-white px-4"
      }>
      <Link to="Home" spy={true} smooth={true} offset={-100} duration={1000}>
        <img
          src={sticky ? LogoColor : Logo}
          alt=""
          className="w-36 cursor-pointer"
        />
      </Link>
      <BiMenuAltRight onClick={handleOpen} className="text-4xl" />
      <div
        className={`fixed top-0 ${
          isOpen ? "right-0 transition-all" : "right-64 transition-all"
        } h-screen w-full ${
          !sticky
            ? "bg-bgtransparent backdrop-blur-md text-white"
            : "bg-white text-black"
        } z-10 flex flex-col shadow-lg transition-all duration-300 transform translate-x-full`}>
        <IoMdClose onClick={handleOpen} className="m-4 text-4xl" />
        <div
          className={`flex flex-col justify-start p-4 space-y-6 text-lg ${
            !sticky ? "text-white" : "text-black"
          }`}>
          {Nav.map((items) => (
            <Link
              onClick={handleOpen}
              to={items.scroll}
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
              className="hover:underline hover:text-secondary p-1 duration-200 ease-in-out cursor-pointer">
              {items.link}
            </Link>
          ))}
          {/* <button
            onClick={() => {
              navigate("/signin");
            }}
            className="bg-primary p-0.5 px-4 w-28 text-white hover:bg-secondary duration-100 ease-in-out">
            Sign In
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default PhoneNav;
