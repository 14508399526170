import React from "react";
import { MdOutlineReadMore } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const MedicalToursim = () => {
  const navigate = useNavigate()
  return (
    <div id="Toursim" className="flex flex-col justify-center items-center md:p-12 p-4">
      <h1 className="text-center md:text-3xl">
        Blue Health Medical Tourism with HBG
      </h1>
      <div className="divider" />
      <p className="text-center md:w-3/4  md:p-5 py-2 md:text-lg">
        We understand how challenging it can be to find quality medicalcare at
        an affordable cost. That's why we are excited to offer a hassle-free and
        one-stop solution to all your medical needs. Starting with a FREE
        Consultation with renowned doctors from India, you can provide your
        patients with Comprehensive Medical Care, Personalized Service and
        Transparent Payment System. We also provide daily follow-ups to ensure
        you feel safe, comfortable and satisfied with the treatment plan.
      </p>
      <button className="flex space-x-3 items-center font-bold md:px-4 px-3 py-1 border-2 border-secondary hover:border-primary duration-100 ease-in-out" onClick={()=>(navigate('/toursimdetail'))}>
        <p className="md:text-xl text-sm">Read More</p>
        <MdOutlineReadMore className="md:text-3xl text-md text-black " />
      </button>
    </div>
  );
};

export default MedicalToursim;
