import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../Assets/Logo Transparent 3.png";
import vector1 from "../../Assets/image/Derash.png";
import vector2 from "../../Assets/Vector/Research..jpg";
import vector3 from "../../Assets/image/merge image.jpg";

const ServiceDetail = () => {
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      header: "Health App",
      text: "To aid our first aid training, we have designed a mobile application that helps trainees in times of emergency. The application features a personal profile, a list of emergency situations with their management and detailed explanations, emergency service numbers, and a list of the nearby health institutions. Derash is prepared in three different languages I.e. English, Amharic & Affan Oromo languages covering some of the most spoken languages in our country (more local languages to be added soon) which help increase the reach of the app to different part of the country where users can use the contents in the app to educate themselves on how to address the most common emergency situations, and on top of that they have the access to call to their nearest Hospitals, Emergency Ambulances and while being guided on what to do and not to do when in the middle of an emergency crisis.",
      img: vector1,
    },
    {
      id: 2,
      header: "Research",
      text:
        "We are pioneering research initiatives to improve healthcare and well-being across Ethiopia, with a primary focus on preventive medicine, pre-hospital care, and hospital care. Our dedicated team of experts is committed to understanding health issues, advancing medical knowledge, innovative technologies, and sustainable healthcare solutions. With a passion for excellence and a community-driven approach, we strive to make a positive impact on the health of Ethiopians by preventing illness and enhancing pre-hospital and hospital care. Join us on our journey toward a healthier and happier nation." +
        "Research focus areas: " +
        "Prehospital and hospital emergency care" +
        "Surgical care" +
        "Pain management",
      img: vector2,
    },
    {
      id: 3,
      header: "Traning",
      text:
        "We provide high-quality, up to date and relevant training content on basic life support to construction companies, high school students and teachers, bank and insurance services, drivers, and the general population as a whole." +
        "The training is delivered by physicians who have vast knowledge, experience and are certified as Training of Trainers (TOT) in Advanced Life Support (ALS) and Basic Life Support (BLS). The training is taught in local languages through a mix of theory, practical activities, and group discussions. Training documents and additional materials will be provided to the participant during the training and certification upon completion." +
        "We also provide training and learning activities through which health professionals maintain and develop throughout their careers to ensure that they retain their capacity to practice safely, effectively, and legally within their evolving scope of practice. In alignment with this, our company provides high-quality, up to date and relevant training to all health care professionals in the country including general practitioners, health officers, paramedics, and others by crediting CEU that are mandatory for medical license renewal.",
      img: vector3,
    },
  ];
  const { id } = useParams();
  const items = data.find((item) => item.id === Number(id));
  const isButtonEnabledNext = data.some((item) => item.id === items.id + 1);
  const isButtonEnabledPrev = data.some((item) => item.id === items.id - 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="flex flex-col justify-center items-center overflow-hidden">
      <div className="flex flex-row justify-between items-center md:px-9 md:py-4 py-3 px-4 w-full">
        <Link to={"/"}>
          <img src={logo} alt="" className="md:w-40 w-36" />
        </Link>
        <Link
          to={"/"}
          className="text-black md:text-md text-sm font-bold hover:text-secondary duration-150 ease-in-out">
          BACK TO HOME
        </Link>
      </div>
      <div className="flex md:flex-row flex-col justify-center items-center">
        <img src={items.img} alt="" className="md:w-2/4 w-full md:p-9 p-4" />
        <div className="flex flex-col md:justify-start justify-center md:items-start items-center md:p-5 p-4">
          <h1>{items.header}</h1>
          <p className="md:text-start text-center w-full md:text-lg py-3">
            {items.text}
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center w-full px-12 mb-10 text-white">
        <button
          onClick={() => {
            if (isButtonEnabledPrev) {
              navigate(`/servicedetail/${parseInt(id) - 1}`);
            }
          }}
          className={`px-3 py-1 bg-primary duration-150 ease-in-out ${
            isButtonEnabledPrev ? "hover:bg-secondary" : "disabled"
          }`}>
          Previous
        </button>
        <button
          disabled={!isButtonEnabledNext}
          onClick={() => {
            if (isButtonEnabledNext) {
              navigate(`/servicedetail/${parseInt(id) + 1}`);
            }
          }}
          className={`px-3 py-1 bg-primary  duration-150 ease-in-out ${
            isButtonEnabledNext ? "hover:bg-secondary" : "disabled"
          }`}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ServiceDetail;
