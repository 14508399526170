import React from "react";
import { MdOutlineLocationOn, MdOutlinePhone } from "react-icons/md";
import Email from "../../Component/Email";

const ContactUs = () => {
  return (
    <div id="Contact" className="flex flex-col md:flex-row md:justify-start justify-center items-center">
      <div className="flex flex-col justify-start  p-12">
        <h1 className="md:text-3xl">Contact Us</h1>
        <div className="divider" />
        <div className="flex items-center py-3 space-x-4">
          <MdOutlineLocationOn className="text-3xl text-secondary" />
          <p className="md:text-lg">4 kilo Gelen building 2nd & 4th floor</p>
        </div>
        <div className="flex items-center space-x-2">
          <MdOutlinePhone className="text-3xl text-secondary" />
          <div className="bg-secondary w-1 h-10 rounded-full" />
          <div className="">
            <p className="md:text-lg">+251 972 387 787</p>
            <p className="md:text-lg">+251 974 041 407</p>
          </div>
        </div>
      </div>
      <Email />
    </div>
  );
};

export default ContactUs;
