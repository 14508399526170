import React from "react";
import logo from "../../Assets/Logo Transparent 1.png";
import { Link } from "react-scroll";
import { FaInstagram } from "react-icons/fa";
import { LiaTelegramPlane } from "react-icons/lia";
import { PiTiktokLogo } from "react-icons/pi";
import { FiFacebook, FiLinkedin, FiYoutube } from "react-icons/fi";

const Footer = () => {
  const links = [
    { link: "Home", scroll: "Home" },
    { link: "Services", scroll: "Services" },
    { link: "Medical Toursim", scroll: "Toursim" },
    { link: "News Feed", scroll: "News" },
    { link: "About Us", scroll: "About" },
    { link: "Contact Us", scroll: "Contact" },
  ];
  const icons = [
    {
      icon: FaInstagram,
      name: "Instagram",
      social: "https://www.instagram.com/bluehealthethiopia",
    },
    {
      icon: LiaTelegramPlane,
      name: "Telegram",
      social: "https://t.me/bluehealthethiopia",
    },
    { icon: PiTiktokLogo, name: "Tiktok", social: "" },
    {
      icon: FiFacebook,
      name: "Facebook",
      social: "https://www.facebook.com/bluehealthethiopia",
    },
    {
      icon: FiLinkedin,
      name: "Linkedin",
      social: "www.linkedin.com/company/blue-health-ethiopia",
    },
    {
      icon: FiYoutube,
      name: "Youtube",
      social: "https://www.youtube.com/@bluehealthethiopia",
    },
  ];
  return (
    <div className="flex flex-col justify-center md:items-start items-center py-5 px-12 bg-black">
      <img src={logo} alt="" className="w-44" />
      <div className="flex flex-row justify-center items-center md:space-x-96 space-x-20">
        <div className="flex flex-col items-start p-3">
          <div className="text-white flex flex-col items-start">
            <h1>Links</h1>
            <div className="w-20 h-0.5 bg-secondary my-0.5 rounded-full" />
          </div>
          <div className="flex flex-col items-start gap-3 w-full my-2 text-white text-sm ">
            {links.map((items, index) => (
              <Link
                to={items.scroll}
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className="hover:scale-110 duration-100 ease-in-out cursor-pointer">
                {items.link}
              </Link>
            ))}
          </div>
        </div>
        <div className="p-3">
          <div className="text-white flex flex-col items-start">
            <h1>Social Media</h1>
            <div className="w-20 h-0.5 bg-secondary my-0.5 rounded-full" />
          </div>
          <div className="flex flex-col items-start gap-3 w-full my-2 text-white text-sm ">
            {icons.map((items, index) => (
              <a
                href={items.social}
                className="flex items-center space-x-3 hover:scale-110 duration-100 ease-in-out cursor-pointer">
                <items.icon className="text-xl text-secondary" />
                <p>{items.name}</p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
